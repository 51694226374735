import cn from 'classnames';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedSettingsSection } from '../../../store/Navigation/selector';
import DefaultCard from '../../Cards/DefaultCard';
import { SettingSectionTypes } from '../SettingSectionTypes';
import './index.scss';

interface SettingsSectionProps {
    title: string;
    description: string;
    sectionType: SettingSectionTypes;
    icon?: string;
    onClick: () => void;
}

const SettingsSection = (props: SettingsSectionProps) => {
    const { title, description, onClick, sectionType } = props;
    const selectedSection = useSelector((state) => selectSelectedSettingsSection(state)) as SettingSectionTypes;
    const [isSelected, setIsSelected] = React.useState(false);

    useEffect(() => {
        setIsSelected(sectionType === selectedSection); 
    }, [selectedSection, sectionType]);

    return (
        <DefaultCard selected={isSelected} onClick={onClick}>
                <div className={cn("settings_section")}>
                    <div className="settings_section_title"> {title} </div>
                    <div className="settings_section_description"> {description} </div>
                </div>
        </DefaultCard>
    );
}

export default SettingsSection;