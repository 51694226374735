import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../../../utils/ThemeContext';
import Toggle from '../../../Buttons/Toggle/Toggle';
import './index.scss';

const DarkModeToggle = () => {
    const { setTheme, theme } = useContext(ThemeContext)

    const toggleDarkMode = () => {
        setTheme( theme === 'light' ? 'dark' : 'light');
    }

    return (
        <div className="dark_mode_container">
            <div>Dark Mode:</div>
            <Toggle onClick={() => toggleDarkMode()} on={theme === 'dark'} />
        </div>
    );
}

export default DarkModeToggle;