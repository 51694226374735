import React from 'react';
import DarkModeToggle from './DarkMode';
import './index.scss';

interface CustomizationProps {

}

const Customization = (props: CustomizationProps) => {

    return (
        <div className="customization_container">
            <DarkModeToggle/>
        </div>
    );
}

export default Customization;