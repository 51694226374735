import React from 'react';
import './index.scss';
import BackButton from '../../Buttons/BackButton';

type NavElementProps = {
  title: string,
  onBack: () => void,
}

const NavElement:React.FC<NavElementProps> = ({title, onBack}) => {
  return (
    <div className="nav-element">
      <BackButton onBack={onBack} />
      <h4>{title}</h4>
    </div>
  )
}

export default NavElement;