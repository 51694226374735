import React from 'react';
import Customization from '../Customization';
import { SettingSectionTypes } from '../SettingSectionTypes';
import './index.scss';

interface SettingsPaneProps{
    sectionType: SettingSectionTypes;
}

const SettingsPane = (props: SettingsPaneProps) => {
    const { sectionType } = props;

    return (
        <div className="settings_pane">
            <h4> { sectionType } </h4>
            { sectionType === SettingSectionTypes.Customization && <Customization /> }
        </div>
    );
}

export default SettingsPane;