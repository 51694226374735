import React, { createContext, useState, useEffect } from 'react'

type ThemeName = 'light' | 'dark' | string
type ThemeContextType = {
    theme: ThemeName
    setTheme: (name: ThemeName) => void
}

const getInitialTheme = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const storedPrefs = window.localStorage.getItem('color-theme')
        if (typeof storedPrefs === 'string') {
            return storedPrefs
        }

        const userMedia = window.matchMedia('(prefers-color-scheme:dark)')
        if (userMedia.matches) {
            return 'dark'
        }
    }
    // returning default theme here
    return 'light'
}

export const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType)

interface ThemeProviderProps {
    children: React.ReactNode,
    initialTheme?: ThemeName,
}

export const ThemeProvider = (props: ThemeProviderProps) => {
    const { children, initialTheme } = props;
    const [theme, setTheme] = useState<ThemeName>(getInitialTheme);


    const rawSetTheme = (theme: ThemeName) => {
        const root = window.document.documentElement;
        const isDark = theme === 'dark';

        root.classList.remove(isDark ? 'light' : 'dark');
        root.classList.add(theme);

        localStorage.setItem('color-theme', theme);
    }

    if (initialTheme) {
        rawSetTheme(initialTheme);
    }

    useEffect(() => {
        rawSetTheme(theme)
    }, [theme]);

    return (
        <ThemeContext.Provider value={ { theme, setTheme } }> 
            { children } 
        </ThemeContext.Provider>
    );
}