import React from 'react';
import './BackButton.scss';

type BackButton = {
  onBack: () => void
}

const BackButton: React.FC<BackButton> = ({ onBack }) => {

  return (
    <button className="back__button" onClick={() => onBack()} />
  )
}

export default BackButton;