import React from 'react';
import cn from 'classnames';
import './Toggle.scss';

interface ToggleProps {
  on: boolean;
  onClick: () => void;
}

const Toggle = (props: ToggleProps) => {
  const { on, onClick } = props;

  return (
    <div className={cn('toggleContainer', { ['toggleOn']: on })} onClick={onClick}>
      <div className={cn({['on']: on, ['off']: !on})}>
        <span />
      </div>
    </div>
  )
}

export default Toggle;
