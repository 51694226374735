import React from 'react';
import { useSelector } from 'react-redux';
import NavElement from '../components/Dashboard/NavElement';
import SettingsContainer from '../components/Settings/SettingsContainer';
import { SettingSectionTypes } from '../components/Settings/SettingSectionTypes';
import SettingsPane from '../components/Settings/SettingsPane';
import SettingsSection from '../components/Settings/SettingsSection';
import { useAppDispatch } from '../store';
import { setSelectedSettingsSection } from '../store/Navigation/reducer';
import { selectSelectedSettingsSection } from '../store/Navigation/selector';

const Settings = () => {
    const dispatch = useAppDispatch();
    const currentSection = useSelector(state => selectSelectedSettingsSection(state)) as SettingSectionTypes;

    const setSettingsSection = (section: SettingSectionTypes) => {
        dispatch(setSelectedSettingsSection(section));
    }

    return (
        <>
            <SettingsContainer>
                <NavElement onBack={() => console.log('Go back to last tab from Settings')} title="Settings" />
                <hr />
                <SettingsSection title="Account" description="Manage your account" sectionType={SettingSectionTypes.Account} onClick={() => setSettingsSection(SettingSectionTypes.Account)} />
                <SettingsSection title="Customization" description="Customize your experience" sectionType={SettingSectionTypes.Customization} onClick={() => setSettingsSection(SettingSectionTypes.Customization)} />
            </SettingsContainer>
            <SettingsPane sectionType={currentSection} />
        </>
    );
}

export default Settings;