import React from 'react';
import cn from 'classnames';
import './DefaultCard.scss';

type DefaultCardProps = {
  highlighted?: boolean,
  onClick: any,
  selected: boolean,
}

const DefaultCard: React.FC<DefaultCardProps> = ({ children, highlighted = true, onClick, selected }) => (
  <div onClick={() => onClick()} className={cn(["default_card", { ['highlighted']: highlighted, ['selected']: selected }])}>
    {children}
  </div>
)

export default DefaultCard;